import React from 'react'
import ReactGA from 'react-ga'

import { StaticQuery, graphql } from 'gatsby'

import $ from 'jquery'

class CookieBanner extends React.Component {
  constructor(props) {
    super(props)
    this.disableStr = null
    this.gaID = null

    this.initCookieBanner = this.initCookieBanner.bind(this)
  }

  componentDidMount() {
    var status = this.getCookie(this.disableStr)

    if (status === undefined || status === null) {
      this.initCookieBanner(this.disableStr, this.gaID)
    }
  }

  getCookie(cname) {
    if (typeof window !== 'undefined') {
      var name = cname + '='
      var decodedCookie = decodeURIComponent(document.cookie)
      var ca = decodedCookie.split(';')
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
    }
    return null
  }

  initCookieBanner(disableStr, gaID) {
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: '#126A81',
          text: '#ffffff',
        },
        button: {
          background: '#00DAB8',
          text: '#ffffff',
        },
      },
      revokable: false,
      type: 'opt-in',
      content: {
        dismiss: 'Akzeptieren!',
        allow: 'Cookies erlauben',
        deny: 'Ablehnen',
        message:
          'Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu.',
        link: 'Mehr Informationen',
        href: 'https://www.ksrechtsanwaelte.de/datenschutz',
        close: '&#x274c;',
        policy: 'Cookie Policy',
        target: '_blank',
      },
      onStatusChange: function(status, chosenBefore) {
        var type = this.options.type
        var didConsent = this.hasConsented()

        if (type === 'opt-in' && didConsent) {
          // enable cookies
          window[disableStr] = false
          document.cookie =
          disableStr + '=allow; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/'
          $('.cc-revoke.cc-bottom.cc-animate').remove()
          ReactGA.initialize(gaID, { debug: true })
        }
        if (type === 'opt-in' && !didConsent) {
          // disable cookies
          window[disableStr] = true
          document.cookie =
          disableStr + '=deny; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/'
          $('.cc-revoke.cc-bottom.cc-animate').remove()
          ReactGA.initialize(gaID, { debug: true })
        }
      },
    })
  }

  render() {
    return (
      <StaticQuery
        query={detailsQuery}
        render={data => {
          this.disableStr = data.site.siteMetadata.gaDisableString
          this.gaID =  data.site.siteMetadata.gaID;
          return <div />
        }}
      />
    )
  }
}

export default CookieBanner

const detailsQuery = graphql`
  query cookieBannerQuery {
    site {
      siteMetadata {
        title
        gaDisableString
      }
    }
  }
`
