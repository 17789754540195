import React from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'

import Header from './header'
import './layout.scss'

import $ from 'jquery'

import CookieBanner from './CookieBanner/index.jsx'

class Layout extends React.Component {
  componentDidMount() {

    var status = this.getCookie(this.disableStr)
    if (
      typeof window !== 'undefined' && status === 'deny'
    ) {
      window[this.disableStr] = true
    } else {
      window[this.disableStr] = false
      ReactGA.initialize(this.gaID, { debug: true })
      console.log('init google analytics complete.')
    }

    if (window !== undefined) {
      $(document).ready(function() {
        $('.jump-to').on('click', function(e) {
          var href = $(this).attr('href')
          $('html, body').animate(
            {
              scrollTop: $(href).offset().top - 80,
            },
            'slow'
          )
          e.preventDefault()
        })
      })
    }
  }

  getCookie(cname) {
    if (typeof window !== 'undefined') {
      var name = cname + '='
      var decodedCookie = decodeURIComponent(document.cookie)
      var ca = decodedCookie.split(';')
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
    }
    return null
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
                gaDisableString
                gaID
              }
            }
          }
        `}
        render={data => {
          this.disableStr = data.site.siteMetadata.gaDisableString;
          this.gaID =  data.site.siteMetadata.gaID;
          return (
          <div>
            <Header siteTitle={data.site.siteMetadata.title} />
            <main>{this.props.children}</main>
            <footer className="margin-120-top">
              <div className="container">
                <div className="row align-items-end">
                  <div className="col-12 col-md-6">
                    <p className="text-center text-md-left">
                      K&S Rechtsanwälte
                      <br /> Dresdener Str. 11
                      <br /> 65474 Bischofsheim
                      <br /> Tel: +49 (0) 06144 / 4029610
                      <br /> Fax: +49 (0) 06144 / 4029619
                      <br /> E-Mail:
                      <a href="mailto:info@ksrechtsanwaelte.de">
                        info@ksrechtsanwaelte.de
                      </a>
                    </p>
                  </div>
                  <div className="col-12 col-md-6">
                    <p className="text-center text-md-right">
                      <Link className="link" to="/impressum">
                        Impressum
                      </Link>
                      <Link className="link" to="/datenschutz">
                        Datenschutz
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </footer>
            <CookieBanner />
          </div>
          )
        }}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
