import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import Logo from './logo.jsx'

class Header extends React.Component {

  render() {
    return (
      <nav
        id="navbar-example2"
        className="navbar fixed-top navbar-expand-lg navbar-light"
        style={{ padding: 0 }}
      >
        <Link className="navbar-brand" to="/">
          <Logo />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav justify-content-end">
            <li className="nav-item">
              <a className="nav-link jump-to" href="#section-start">
                Start
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link jump-to" href="#section-rechtsgebiete">
                Rechtsgebiete
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link jump-to" href="#rechtsanwaelte">
                Anwälte
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link jump-to" href="#section-standort">
                Standort
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link jump-to" href="#section-kontakt">
                Kontakt
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link jump-to" href="#section-cost">
                Kosten
              </a>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
